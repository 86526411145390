<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <h1>Dashboard</h1>
          <p>
            <router-link to="add-file" tag="button" class="button">
              Upload CSV
            </router-link>
            &nbsp;
            <button v-on:click="buildLegacyOptions" class="button">
              Build Legacy Options
            </button>
            &nbsp;
            <button v-on:click="buildTffOptions" class="button">
              Build TFF Options
            </button>
          </p>
          <p>
            <router-link to="/view-open-interest" class="button">
              Open Interest
            </router-link>
            &nbsp;
            <router-link to="/view-legacy" class="button">
              Legacy
            </router-link>
            &nbsp;
            <router-link to="/view-tff" class="button">
              TFF
            </router-link>
          </p>
          <p>
            <!--
              <router-link to="/view-legacy-futures" class="button">
                Legacy Futures
              </router-link>
              &nbsp;
              <router-link to="/view-legacy-options" class="button">
                Legacy Options
              </router-link>
              &nbsp;
              <router-link to="/view-legacy-combined" class="button">
                Legacy Combined
              </router-link>
              &nbsp;
              <router-link to="/view-tff-futures" class="button">
                TFF Futures
              </router-link>
              &nbsp;
              <router-link to="/view-tff-options" class="button">
                TFF Options
              </router-link>
              &nbsp;
              <router-link to="/view-tff-combined" class="button">
                TFF Combined
              </router-link>
            -->
          </p>
        </div>
      </div>
    <div
      v-if="message.length > 0"
      class="message-wrap">
      <span class="close-message" v-on:click="reload()">X</span>
      <p class="message-text">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'Dashboard',
  data() {
    return {
      message: '',
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
  },
  methods: {
    buildLegacyOptions() {
      axios.get(`/buildOptions/buildLegacyOptions.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          if (response.data.response === true) {
            this.message = 'The Legacy options have been built successfully';
          } else {
            this.message = 'The Legacy options could not be built - please try again';
          }
        });
    },
    buildTffOptions() {
      axios.get(`/buildOptions/buildTffOptions.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          if (response.data.response === true) {
            this.message = 'The TFF options have been built successfully';
          } else {
            this.message = 'The TFF options could not be built - please try again';
          }
        });
    },
    reload() {
      // eslint-disable-next-line
      location.reload();
    },
    clearMessage() {
      this.message = '';
    },
  },
};
</script>
